import React, { Component } from 'react';
import SimpleModal from '../components/utilities/SimpleModal';
// import AnchorLink from 'react-anchor-link-smooth-scroll';

import Layout from '../components/layout';
import SEO from "../components/seo";

import './casestudyanywhereapp.scss';

import imgQuote from "../images/quotation_mark.webp";

// import SimpleModal from '../components/utilities/SimpleModal';

class FullWidthVideo extends Component {
    constructor(props) {
        super(props);
        this.modalRef = React.createRef();
        this.openModal = this.openModal.bind(this);
    };
    openModal() {
        this.modalRef.current.handleOpen();
    };
    render() {
        const divStyles = {
            backgroundImage: 'url('+this.props.image+')',
            height: this.props.height
        };
        return (
            <div>
                <div className="container-fluid full-width-video-wrapper" onClick={() => this.openModal()} style={divStyles}>&nbsp;</div>
                <SimpleModal videoSRC={this.props.videoSRC} innerRef={this.modalRef}/>
            </div>
        );
    }
}


export default ({pageContext}) => (
    <Layout>
        <SEO 
            title={pageContext.yoast.title}
            description={pageContext.yoast.metadesc}
            ogimg={pageContext.yoast.opengraph_image}
            link={pageContext.link}
            />        
        <div className="case-study-one-wrapper">
            <div className="container-fluid">
                <div className="row">
                    <div className="header-container" style={{backgroundImage: `url(${pageContext.acf.header_image.csOneHedImaLocalFile.childImageSharp.fluid.src})`}}>
                        <img src={imgQuote} alt=""/>
                        <h2>{pageContext.acf.header_text}</h2>
                    </div>
                </div>
            </div>
            <div className="container detail-wrapper">
                <div className="row">
                    <div className="col-md-8">
                        <img src={pageContext.acf.client_logo.csOneCliLogLocalFile.childImageSharp.fluid.src} className="client-logo" width={pageContext.acf.client_logo.media_details.width/2} height={pageContext.acf.client_logo.media_details.height/2} alt={pageContext.yoast.opengraph_title} />
                    </div>
                    <div className="col-md-4">
                        <a href="https://www.meritmile.com/uploads/2024/01/merit-mile-anywhereapp-case-study.pdf" className="btn downloadBtn" target="_blank" rel="nofollow noopener noreferrer" style={{ marginTop: '4em'}}>Download the PDF here</a>
                    </div>
                    <div className="col-12">
                        <div className="bar"></div>
                    </div>
                    <div className="col-12">
                        <div className="first-paragraph" dangerouslySetInnerHTML={{ __html: pageContext.acf.first_paragraph}} />
                    </div>
                    <div className="col-12"> 
                        {pageContext.acf.learn_more_url
                            ? <a href={pageContext.acf.learn_more_url} className={pageContext.acf.learn_more_url ? '' : 'd-none'} target="_blank" rel="noopener noreferrer"><div className="btn btn-grey">Learn More</div></a>
                            : <div className="d-none"><div className="btn btn-grey">Learn More</div></div>
                        }
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6 order-2">
                        <div className="second-paragraph" dangerouslySetInnerHTML={{ __html: pageContext.acf.second_paragraph}} />
                        <div className="third-paragraph" dangerouslySetInnerHTML={{ __html: pageContext.acf.third_paragraph}} />
                    </div>
                    <div className="col-md-6 order-1 order-md-2">
                        <img src={pageContext.acf.desktop_image.csOneDesImaLocalFile.childImageSharp.fluid.src} className="desktop-image img-fluid" width={pageContext.acf.desktop_image.media_details.width} height={pageContext.acf.desktop_image.media_details.height} alt=""/>
                    </div>
                    <div className="col-12 order-3">
                        <div className="fourth-paragraph" dangerouslySetInnerHTML={{ __html: pageContext.acf.fourth_paragraph}} />
                    </div>
                </div>
            </div>
            <div className="container-fluid cta">
                <div className="row">
                    <div className="col-12">
                        <h2 className="ctaTitleOne">Start Creating Business Momentum Today</h2>
                        <a href="#footer" className="btn btn-light">Get In Touch</a>
                    </div>
                </div>
            </div>
            <FullWidthVideo videoSRC={pageContext.childWordPressAcfAddFullWidthVideo.youtube_id} height={pageContext.childWordPressAcfAddFullWidthVideo.background_image.media_details.height/2} image={pageContext.childWordPressAcfAddFullWidthVideo.background_image.source_url} />
            <div className="detail-wrapper">
                <div className="container single-image-text-wrapper">
                    <div className="row">
                        <div className="col-md-6 order-last">
                            <img src="https://www.meritmile.com/uploads/2018/11/anywhere-app-deliverables-1.png" className="img-fluid" width="515" height="325" alt="" />
                        </div>
                        <div className="col-md-6 text">
                            <div className="text">
                                <h3>Deliverables</h3>
                                <ul>
                                    <li>Messaging and Positioning Framework</li>
                                    <li>Product Identity &amp; Brand Guide</li>
                                    <li>Go-to-Market Plan</li>
                                    <li>Go-to-Market Planning Workshop</li>
                                    <li>Anywhere App Website</li>
                                    <li>Customer Facing Presentation</li>
                                    <li>Selling/Positioning Training Presentation</li>
                                    <li>Solution Overview Whitepaper</li>
                                    <li>Custom Icon Development</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="detail-wrapper">
                <div className="container single-image-text-wrapper">
                    <div className="row">
                        <div className="col-md-6 text-center">
                            <img src="https://www.meritmile.com/uploads/2018/11/anywhereapp-hand.png" className="img-fluid" width="673" height="421" alt="" />
                        </div>
                        <div className="col-md-6 text">
                            <div className="text">
                                <ul>
                                    <li>Video – Business Level</li>
                                    <li>Case Study</li>
                                    <li>Sales Reference Card</li>
                                    <li>Tele-Sales Guide</li>
                                    <li>Email Campaigns</li>
                                    <li>Social Media</li>
                                    <li>Demand Generation Toolkit</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container three-image-wrapper">
                <div className="row align-items-center">
                    <div className="col-md-4">
                        <img src="https://www.meritmile.com/uploads/2018/11/anywhere-illustration-1.jpg" className="img-fluid" alt="" />
                    </div>
                    <div className="col-md-4">
                        <img src="https://www.meritmile.com/uploads/2018/11/anywhere-illustration-2-1.jpg" className="img-fluid" alt="" />
                    </div>
                    <div className="col-md-4">
                        <img src="https://www.meritmile.com/uploads/2018/11/anywhere-illustration-3.jpg" className="img-fluid" alt="" />
                    </div>
                </div>
            </div>
            <div className="container one-image-wrapper">
                <div className="row align-items-center">
                    <div className="col-12">
                        <img src="https://www.meritmile.com/uploads/2018/11/anywhereapp-bottom-image.jpg" className="img-fluid" alt="" />
                    </div>
                </div>
            </div>
            <div className="container quote-wrapper">
                <div className="row">
                    <div className="col-12">
                        <p>"A world-class website begins as a brief conversation, let's talk."</p>
                    </div>
                </div>
            </div>

            
        </div>
    </Layout>
)